import { createStore } from 'vuex';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import investing from '../investing';

const { getInvestor, getPortfolio } = investing;

export default createStore({
  state: {
    user: null,
    error: null,
    investor: null,
    portfolios: {},
  },
  mutations: {
    signIn(state, user) {
      state.user = user;
      state.error = null;
    },
    signError(state, error) {
      state.error = error;
    },
    signOut(state) {
      state.user = null;
      state.error = null;
    },
    investor(state, investor) {
      state.investor = investor;
    },
    portfolio(state, portfolio) {
      state.portfolios = {
        ...state.portfolios,
        ...portfolio,
      };
    },
  },
  actions: {
    signIn(context, { email, password }) {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const { user } = userCredential;
          context.commit('signIn', user);
        })
        .catch((error) => {
          context.commit('signError', error);
        });
    },
    signOut(context) {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          context.commit('signOut');
        })
        .catch((error) => {
          context.commit('signError', error);
        });
    },
    async fetchInvestor(context, user) {
      const { uid } = user;
      const investor = await getInvestor(uid);
      const { investments } = investor;
      if (investments) {
        investments.forEach((investment) => {
          const { portfolio } = investment;
          context.dispatch('fetchPortfolio', portfolio);
        });
      }
      context.commit('investor', investor);
    },
    async fetchPortfolio(context, portfolioName) {
      const portfolio = await getPortfolio(portfolioName);
      const { value } = portfolio;
      context.commit('portfolio', {
        [portfolioName]: value,
      });
    },
  },
  modules: {
  },
});
