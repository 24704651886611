<template>
  <form @submit.prevent="onSubmit" class="box text-center">
    <h2>Iniciar sesion</h2>
    <div class="column">
      <label for="user">Correo</label>
      <input
        required
        type="email"
        id="email"
        name="email"
        class="input"
        autofocus
        v-model="email"
      >
      <label for="password">Contrasena</label>
      <input
        required
        type="password"
        id="password"
        name="password"
        class="input"
        v-model="password"
      >
      <span v-if="error" class="error">{{ error }}</span>
      <button type="submit" class="button">Iniciar sesion</button>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState(['user', 'error']),
  },
  methods: {
    ...mapActions(['signIn', 'fetchInvestor']),
    onSubmit() {
      this.signIn({
        email: this.email,
        password: this.password,
      });
    },
  },
  watch: {
    user(value) {
      if (value !== null) {
        this.fetchInvestor(value);
        this.$router.push('/dashboard');
      }
    },
  },
  setup() {
  },
};
</script>

<style scoped>
  .text-center {
    text-align: center;
  }

  .input {
    background-color: var(--background);
    color: white;
    border: 0px;
    padding: 5px;
  }

  .box {
    background-color: var(--primary);
    border-radius: 15px;
    padding: 15px;
  }

  .column {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
  }

  .column > * {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .column > :first-child {
    margin-top: 0px;
  }

  .column > :last-child {
    margin-bottom: 0px;
  }

  .button {
    background-color: var(--accent);
    border-radius: 15px;
    border: 0px;
    padding: 5px;
  }

  .error {
    color: var(--error);
    background-color: var(--background);
    padding: 5px;
  }
</style>
