<template>
  <div>
    <Login class="wide"/>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';

export default {
  name: 'Home',
  components: {
    Login,
  },
};
</script>

<style scoped>
  .wide {
    width: 100%vw;
    max-width: 300px;
  }
</style>
