import getDocument from './firebase';

const getInvestor = (id) => getDocument(['investors', id]);
const getPortfolio = (portfolio) => getDocument([portfolio, 'latest']);

const calculateInvestmentGrowth = (investment, investmentValue) => {
  const { capital } = investment;

  return ((investmentValue / capital) - 1) * 100;
};

const calculateInvestmentValue = (investment, portfolioValue) => {
  const { share } = investment;

  return portfolioValue * share;
};

const cleanPortfolioName = (text) => text.replace('-', ' ');

const twoFractionDigits = (number) => number.toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default {
  calculateInvestmentGrowth,
  calculateInvestmentValue,
  cleanPortfolioName,
  getInvestor,
  getPortfolio,
  twoFractionDigits,
};
