import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

const reportUrl = 'https://datastudio.google.com/reporting/18555357-3916-45cd-ac15-495b50fd2198';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
  },
  {
    path: '/report',
    name: 'Report',
    // eslint-disable-next-line
    beforeEnter() { location.href = reportUrl; },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const isAuthenticated = () => {
  if (store.state.user !== null) {
    return true;
  }

  return false;
};

router.beforeEach((to, from, next) => {
  if (to.name !== 'Home' && !isAuthenticated()) next({ name: 'Home' });
  else next();
});

export default router;
