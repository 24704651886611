<template>
  <Navigation/>
  <router-view/>
</template>

<script>
import Navigation from '@/components/Navigation.vue';

export default {
  name: 'App',
  components: {
    Navigation,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');

:root {
  /* https://coolors.co/8ecae6-219ebc-023047-ffb703-fb8500 */
  --primary: #219ebc;
  --secondary: #8ecae6;
  --accent: #ffb703;
  --error: #fb8500;
  --background: #023047;

  --font-family: 'Open Sans', Helvetica;
  --heading-font-family: Montserrat, serif;
}

html, body {
  background-color: var(--background);
  font-family: var(--font-family);
}

h1, h2, h3 {
  font-family: var(--heading-font-family);
}

h1, h2, h3, p {
  margin: 0;
}

#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--background);

  width: 80vw;
  margin: 0 auto;
}
</style>
