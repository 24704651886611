import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  getDoc,
} from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: 'AIzaSyBRompjn40W37PDVGrZNRqPUVrFK1Kd9cA',
  authDomain: 'crypto-fund-mx.firebaseapp.com',
  projectId: 'crypto-fund-mx',
  storageBucket: 'crypto-fund-mx.appspot.com',
  messagingSenderId: '1032329562389',
  appId: '1:1032329562389:web:aa0427f966f941ecce011c',
  measurementId: 'G-DKBFBW7VZJ',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const getDocument = async (paths) => {
  const documentReference = doc(db, ...paths);
  const documentSnapshot = await getDoc(documentReference);

  let data = null;
  if (documentSnapshot.exists()) {
    data = documentSnapshot.data();
  } else {
    console.error(`No document for ${paths}`);
  }

  return data;
};

export default getDocument;
